module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'The Data Guild', // Navigation and Site Title
  siteTitleAlt: 'The Guild', // Alternative Site title for SEO
  siteUrl: 'https://thedataguild.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/guild-shield-1035x1035.png', // Used for SEO and manifest ... needs to be in the 'static' dir
  siteEmbedImage: '/logos/guild-logo-with-bright-background.jpg', // Used for Twitter, FB, Slack embeds
  siteDescription: 'A venture studio in San Francisco, California',

  siteFBAppID: null, // Facebook App ID
  userTwitter: '@dataguild', // Twitter Username
  ogSiteName: 'The Data Guild', // Facebook Site Name
  ogLanguage: 'en_EN', // Facebook Language

  // Manifest and Progress color
  themeColor: '#ffffff',
  backgroundColor: '#ffffff'
}
