import chroma from 'chroma-js'

export default {
  breakpoints: ['32em', '48em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.7
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em'
  },
  fonts: {
    serif: 'merriweather, times, serif',
    sans: `-apple-system, BlinkMacSystemFont, 'Roboto', "Segoe UI", 'Helvetica Neue', 'Helvetica', sans-serif`,
    condensed: `"Roboto Condensed", 'Roboto', "Franklin Gothic Medium", Tahoma, sans-serif`,
    mono: `"SF Mono", "Roboto Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;`
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid'
  ],
  radii: [0, 2, 4, 16, 9999, '100%'],

  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  minWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    black: '#000',
    nearBlack: '#111',
    darkGray: '#333',
    midGray: '#555',
    gray: ' #777',
    silver: '#999',
    lightSilver: '#aaa',
    moonGray: '#ccc',
    lightGray: '#eee',
    nearWhite: '#f4f4f4',
    white: '#fff',
    transparent: 'transparent',
    darkGuildBlue: 'hsl(208, 60%, 35%)',
    guildBlue: 'hsl(221, 92%, 58%)',
    guildYellow: 'hsl(56, 100%, 50%)',
    guildGreen: 'hsl(97, 46%, 30%)',
    guildPurple: 'hsl(272, 100%, 26%)',
    guildLightPurple: chroma('hsl(272, 100%, 26%)')
      .brighten(1)
      .hex(),
    guildDarkPurple: chroma('hsl(272, 100%, 26%)')
      .darken(1.5)
      .hex(),
    guildRed: 'hsl(16, 100%, 50%)',
    blue: 'hsl(221, 92%, 58%)',
    blacks: [
      'rgba(0,0,0,.0125)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.9)'
    ],
    whites: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)'
    ]
  },
  shadows: [
    '0 9px 28px 0 rgba(0, 0, 0, 0.1)',
    '0 9px 28px 0 rgba(0, 0, 0, 0.15)'
  ]
}
