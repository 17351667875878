import React, { Component } from 'react'
import styled from 'styled-components'
import Menu from 'react-burger-menu/lib/menus/slide'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { Box } from 'rebass'
import { MdClose } from 'react-icons/md'
import theme from 'utils/theme'

const navigationGlobalStyles = `
  .bm-cross-button {
    height: 24px;
    width: 24px;

    .bm-cross {
      background: #333;
    }
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  a.active:not([href="/contact"]) {
    border-bottom: 2px solid black;
  }

  .bm-menu-wrap {
    transition-duration: .1s !important;

    .bm-menu {
      background: white;
      padding: 3rem 1rem;
      font-size: 1.25rem;
      line-height: 2.125rem;

      .bm-item-list {
        background: rgba(256, 256, 256, 0.8);
        color: black;
        padding: 0.8em;
      }
    }
  }

`

const MenuLink = styled(Link)`
  margin-bottom: ${theme.space[3]}px;
  padding: ${theme.space[2]}px ${theme.space[3]}px;
  margin-left: ${props => (props.subnav ? theme.space[4] : `0`)}px;
  font-size: ${props =>
    props.subnav ? theme.fontSizes[1] : theme.fontSizes[2]}px;
  border-width: 0;
  &.active {
    font-weight: 700;
  }
  transition: background 0.4s;
  border-radius: ${theme.radii[4]}px;

  &.active,
  &:hover {
    background: ${theme.colors.blacks[2]};
    transition: background 0.4s;
    cursor: pointer;
  }
`

const NavigationRow = styled(Box)`
  align-items: center;
  position: relative;
  display: flex;
  font-size: ${theme.fontSizes[1]}px;
  font-weight: 700;
  justify-content: flex-end;
  margin: 0;

  .logo {
    flex: 1;
    display: flex;
    padding-top: 9px; //todo refactor to avoid magic numbers
  }

  & > div {
    display: inline-block;
    margin: 0 ${theme.space[3]}px;

    &:last-of-type {
      a {
        color: white;
        background-color: ${theme.colors.guildGreen};
        border-radius: ${theme.radii[4]}px;
        padding: ${theme.space[3]}px ${theme.space[4]}px;
      }
    }

    a {
      padding: ${theme.space[3]}px ${theme.space[1]}px;
      color: ${theme.colors.blacks[11]};
    }
  }
`

// Migrated from Airtable
// TODO clean up this data structure @chris 2018-9-14
const navigationData = [
  {
    node: {
      id: 'Airtable_recP6JC18nFv7R2GR',
      data: {
        Label: 'Principles',
        Link: '/principles',
        SubNav: null
      }
    }
  },
  {
    node: {
      id: 'Airtable_recCeMcgofsBsiHJ2',
      data: {
        Label: 'Mission',
        Link: '/mission',
        SubNav: null
      }
    }
  },
  {
    node: {
      id: 'Airtable_rec1cRjnO2X5s8ALN',
      data: {
        Label: 'Process',
        Link: '/process',
        SubNav: null
      }
    }
  },
  {
    node: {
      id: 'Airtable_recP4AUVPISwKZg4u',
      data: {
        Label: 'For members',
        Link: '/for-members',
        SubNav: true
      }
    }
  },
  {
    node: {
      id: 'Airtable_recOIuuYbJueOonre',
      data: {
        Label: 'For companies',
        Link: '/for-companies',
        SubNav: true
      }
    }
  },
  {
    node: {
      id: 'Airtable_reciMEV9cget343wv',
      data: {
        Label: 'Team',
        Link: '/team',
        SubNav: null
      }
    }
  },
  {
    node: {
      id: 'Airtable_recdOxU0KpVz8z9bS',
      data: {
        Label: 'Portfolio',
        Link: '/portfolio',
        SubNav: null
      }
    }
  },
  {
    node: {
      id: 'Airtable_recxYW7gXNtmAQdo5',
      data: {
        Label: 'Contact',
        Link: '/contact',
        SubNav: null
      }
    }
  }
]

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false
    }
  }

  toggleNav() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query NavigationQuery {
            wordmark: file(relativePath: { regex: "/wordmark-sfpro/" }) {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <Box css={navigationGlobalStyles}>
            <Menu
              isOpen={this.state.menuOpen}
              pageWrapId="inner-container-for-menu"
              outerContainerId="outer-container-for-menu"
              className="menu"
              id="menu"
              right
              customBurgerIcon={false}
              customCrossIcon={false}
            >
              <a
                role="button"
                onClick={() => this.toggleNav()}
                onKeyPress={() => this.toggleNav()}
                tabIndex="0"
                style={{ position: 'absolute', top: '20px', right: '20px' }}
              >
                <MdClose />
              </a>

              {navigationData.map(({ node: link }) => {
                return (
                  <MenuLink
                    key={link.id}
                    activeClassName="active"
                    to={link.data.Link}
                    subnav={link.data.SubNav ? 1 : 0}
                    onClick={() => this.toggleNav()}
                    onKeyPress={() => this.toggleNav()}
                  >
                    {link.data.Label}
                  </MenuLink>
                )
              })}
            </Menu>

            <NavigationRow p={[1, 2, 4]} location={this.props.location}>
              <div className="logo">
                <Link to="/">
                  <Img
                    imgStyle={{ objectFit: 'contain' }}
                    fixed={data.wordmark.childImageSharp.fixed}
                  />
                </Link>
              </div>
              <div className="hidden-on-desktop">
                <a
                  role="button"
                  onClick={() => this.toggleNav()}
                  onKeyPress={() => this.toggleNav()}
                  tabIndex="0"
                >
                  Menu
                </a>
              </div>
              {/* Desktop menu row */}
              {navigationData
                .filter(node => !node.node.data.SubNav)
                .map(({ node: link }) => {
                  return (
                    <div className="hidden-on-mobile" key={link.id}>
                      <Link activeClassName="active" to={link.data.Link}>
                        {link.data.Label}
                      </Link>
                    </div>
                  )
                })}
            </NavigationRow>
          </Box>
        )}
      />
    )
  }
}

export default Navigation
