import styled, { keyframes } from 'styled-components'
import { SuperBox } from 'components/SuperBox'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const FadeIn = styled(SuperBox)`
  animation: 0.4s ${fadeIn} ease-out;
`

export const FadeInSlowly = styled(SuperBox)`
  animation: 1s ${fadeIn} ease-out;
`

export const FadeInVerySlowly = styled(SuperBox)`
  animation: 2s ${fadeIn} ease-out;
`
