import system from 'system-components'

// <SuperBox /> is like the Rebass <Box /> but with more props
//
// COMPARE TO: <Box/> https://rebassjs.org/Box
//
// The regular Box supports:
// • space props (m, mx, my, ml, mr, mt, mb, p, px, py, pl, pr, pt, pb)
// • width
// • fontSize
// • color
// • bg
// • css
// • as (`as` is from from styled-componets)
//
// ... SuperBox supports those plus many Flex props (see below)
//
// NOTE: There is presumably a performance penalty; use regular Box from Rebass when possible.
//
// WARNING: If you add things to this list, make sure they are in the styled-system API:
// https://github.com/jxnblk/styled-system
//
// ... adding incorrect property names can fail silently, and cause other styles to fail silently!
//
export const SuperBox = system(
  'alignItems',
  'backgroundImage',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'borderRadius',
  'borderColor',
  'borders',
  'boxShadow',
  'color',
  'display',
  'flex',
  'flexDirection',
  'flexWrap',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'gridColumnGap',
  'gridGap',
  'gridRowGap',
  'gridTemplateColumns',
  'gridTemplateRows',
  'height',
  'justifyContent',
  'maxWidth',
  'minHeight',
  'minWidth',
  'position',
  'space',
  'textAlign',
  'width',
  'zIndex'
)
