import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { createGlobalStyle } from 'styled-components'
import roboto from 'typeface-roboto' // eslint-disable-line
import robotoMono from 'typeface-roboto-mono' // eslint-disable-line
import { Provider, Box } from 'rebass'
import { IconContext } from 'react-icons'
import Navigation from 'components/Navigation'
import theme from 'utils/theme'
import SEO from 'components/SEO'

const GlobalStyles = createGlobalStyle`
  * {
      box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  p a, p a:hover {
    background-color: ${theme.colors.blacks[1]};
    border-bottom: 2px solid ${theme.colors.blacks[6]};
  }

  body {
    margin: 0;
    font-family: ${theme.fonts.sans};
    font-size: ${theme.fontSizes[3]}px;
    line-height: ${theme.lineHeights.copy};
    color: ${theme.colors.blacks[11]};
    overflow-x: hidden;
  }

  img {
    max-width: 100%;
  }
        
  hr {
    margin: ${theme.space[5]}px 0;
    color: ${theme.colors.blacks[3]};
  }

  h1,h2,h3,h4,h5 {
    line-height: 1.2;
  }

  // Hm, these styles are set mostly for the markdown files which are rendered directly to HTML ... TODO — figure out how to render them with standar components from ../components/Typography @chrisb 2018-12-6
  h3, h2 {
    font-size: ${theme.fontSizes[4]}px;
    margin-top: ${theme.space[5]}px;
  }

  .hidden-on-mobile {
    @media all and (max-width: 1000px) {
      display: none !important;
    }
  }

  .hidden-on-desktop {
    @media all and (min-width: 1001px) {
      display: none !important;
    }
  }
`

export default class LayoutPrimary extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutPrimaryQuery {
            site {
              siteMetadata {
                version
              }
            }
          }
        `}
        render={data => (
          <Provider theme={theme}>
            <IconContext.Provider
              value={{ style: { display: 'flex', alignItems: 'center' } }}
            >
              <GlobalStyles />
              <SEO />
              <Box
                fontSize={[2, 2, 3]}
                style={{ position: 'relative', zIndex: 0 }}
                id="outer-container-for-menu"
              >
                <SEO />
                {this.props.navigation !== 'hidden' && (
                  <Navigation location={this.props.location} />
                )}
                <div
                  id="inner-container-for-menu"
                  style={{ overflowX: 'hidden' }}
                >
                  {this.props.children}
                  <Box
                    style={{ position: 'fixed', bottom: 0, right: 10 }}
                    fontSize="8px"
                  >
                    version {data.site.siteMetadata.version}
                  </Box>
                </div>
              </Box>
            </IconContext.Provider>
          </Provider>
        )}
      />
    )
  }
}
