import React from 'react'
import { Box } from 'rebass'
import styled from 'styled-components'
import Link from 'gatsby-link'
import { SuperBox } from 'components/SuperBox'
import Text, { PageCardTitle } from 'components/Typography'
import { FadeIn } from 'components/FadeIn'
import theme from 'utils/theme'

// headerUnderlineStyles
//
// Cards on the LandingLead have this underline
export const headerUnderlineStyles = lineColor =>
  `&::after {
    content: '';
    border-bottom: 4px solid ${lineColor || 'transparent'};
    width: 60px;
    display: block;
    margin-top: 10px;
  }`

// cardBaseStyles
//
// These are the essence of what makes something a "card"
export const cardBaseStyles = `
  display: block;
  border-radius: 10px;
  display: block;
  font-size: ${theme.fontSizes[1]}px;
  font-weight: 400;
  margin-bottom: ${theme.space[4]}px;
  line-height: 1.3;
`

// PageBodyCard
//
// Used in page layouts as big card wrapping most of the body
// The title prop is an h1
export const PageBodyCard = styled(({ ...props }) => (
  <FadeIn {...props}>
    <SuperBox
      boxShadow={props.boxShadow ? props.boxShadow : '0'}
      bg={props.bg ? props.bg : 'white'}
      flex={1}
      my={2}
      px={[3, 3, 5]}
      py={[4, 4, 5]}
      borderRadius="3"
    >
      {props.title ? <PageCardTitle>{props.title}</PageCardTitle> : null}
      <Box>
        <Text lineHeight={theme.lineHeights.copy} fontSize={[1, 2, 3]}>
          {props.body ? props.body : null}
          {props.children ? props.children : null}
        </Text>
      </Box>
    </SuperBox>
  </FadeIn>
))`
  ${cardBaseStyles};
`

// SimpleFadeInCard
//
// Like PageBodyCard but without the optional title ...
// TODO — probably refactor this one away @chrisb 2018-11-14
export const SimpleFadeInCard = ({ expanded, ...props }) => (
  <FadeIn
    bg={props.bg ? props.bg : theme.colors.whites[11]}
    boxShadow={theme.shadows[0]}
    borderRadius={expanded ? 3 : 2}
    w={1}
    mb={3}
    p={expanded ? [4, 4, 5] : 3}
    minWidth="150px"
    height={props.height ? props.height : '100%'}
    {...props}
  />
)

// LinkCard — links to internal page
//
// This is an example of an extended styled-component
// where we remove the prop lineColor which we don't want to be passed in to Link
export const LinkCard = styled(({ lineColor, ...props }) => (
  <Link {...props} />
))`
  ${cardBaseStyles} ${props => headerUnderlineStyles(props.lineColor)}
  background-color: ${props => (props.bg ? props.bg : theme.colors.whites[11])};
  color: ${props => (props.color ? props.color : theme.colors.blacks[9])};
  max-width: ${theme.space[8]}px;
  font-weight: 800;
  padding: ${theme.space[4]}px;
  box-shadow: ${theme.shadows[0]};
  ${props => props.css}
`
// HrefCard — Link to external page
//
export const HrefCard = styled(({ lineColor, ...props }) => (
  <Box width={props.width}>
    <a href={props.to} {...props} />
  </Box>
))`
  ${cardBaseStyles} font-weight: 800;
  background-color: white;
  ${props => headerUnderlineStyles(props.lineColor)};
  padding: ${theme.space[4]}px;
  box-shadow: ${theme.shadows[0]};
`
