import React from 'react'
import styled from 'styled-components'
import system from 'system-components'
import { Heading, Text, Box } from 'rebass'
import Link from 'gatsby-link'
import {
  space,
  width,
  fontSize,
  fontWeight,
  color,
  textAlign,
  textTransform,
  justifyContent,
  responsiveStyle,
  minHeight,
  borders,
  borderRadius,
  borderColor,
  borderWidth,
  hover,
  maxWidth
} from 'styled-system'
import theme from 'utils/theme'

export default system(
  'fontWeight',
  'borders',
  'borderRadius',
  'minHeight',
  'space',
  'width',
  'color',
  'textAlign',
  'fontFamily',
  'fontSize',
  'lineHeight',
  'borderColor',
  'hover',
  'maxWidth'
)

export const SectionHeader = system({
  textAlign: 'left',
  width: 1,
  fontWeight: 700,
  fontSize: [3, 3, 4],
  color: 'black',
  m: 0,
  p: 0
})

export const SectionLeadText = system({
  textAlign: 'left',
  width: 1,
  fontSize: [2, 2, 3],
  my: [4, 4, 5]
})

export const HeaderAccent = system(
  {
    textAlign: 'center',
    width: 1,
    fontSize: [3, 3, 4],
    color: theme.colors.blue,
    letterSpacing: theme.letterSpacings.tracked,
    fontWeight: 700
  },
  props => ({
    textTransform: 'uppercase'
  })
)

export const MetadataLabel = props => (
  <Text
    pt={3}
    pb={3}
    fontSize={[0, 0, 1]}
    color="blacks.7"
    style={{ textTransform: 'uppercase' }}
    {...props}
  />
)

export const CardHeading = props => (
  <Heading pb={2} fontSize={props.expanded ? 3 : 2} {...props} />
)

export const FinePrintColumn = props => (
  <Box {...props} mt={2} width={[1, 1, 1 / 4]} pr={4} />
)

export const FinePrintHeading = props => (
  <Heading mb={4} fontSize={theme.fontSizes[0]} {...props} />
)

export const FinePrintLink = props => (
  <Box pb={3}>
    <Text lineHeight={1.2}>
      <Link {...props} />
    </Text>
  </Box>
)

export const CardTitle = ({ expanded, ...props }) => (
  <Text
    as="h4"
    mt={0}
    mb={1}
    fontSize={expanded ? [3, 3, 4] : [2, 2, 3]}
    {...props}
  />
)

export const PageCardTitle = ({ expanded, ...props }) => (
  <Text as="h1" mt={[0, 2, 4]} mb={[1, 3, 5]} fontSize={[4, 4, 5]} {...props} />
)

export const PersonalLink = ({ expanded, ...props }) => (
  <Text
    bg="blacks.2"
    fontWeight={3}
    fontSize={expanded ? [0, 0, 1] : 0}
    mr={expanded ? [2, 2, 3] : 1}
    mb={1}
    p={2}
    px={3}
    borderRadius={3}
    lineHeight="1.4"
    color="guildBlue"
    css={`
      &:hover {
        color: ${theme.colors.darkGuildBlue};
      }
    `}
    {...props}
  />
)

export const DateStamp = props => (
  <Text
    color="blacks.6"
    fontFamily="'Roboto Mono', monospace"
    fontSize={0}
    pt={0}
    {...props}
  />
)

export const Description = ({ expanded, ...props }) => (
  <Text fontSize={expanded ? [1, 1, 2] : 2} mt={3} pb={4} {...props} />
)
